import React from 'react';
import { string } from 'prop-types';

import Link from '../../../../link';

const ProcessedDescription = ({ id = '', name = '', url = '' }) => (
  <Link title={name} href={url}>
    <b>{name}</b>
    <br />({id})
  </Link>
);

ProcessedDescription.propTypes = {
  id: string,
  name: string,
  url: string,
};

export default ProcessedDescription;
