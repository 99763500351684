import React from 'react';

import { Button, ButtonText } from '@andes/button';

import FilterTooltip from '../../filters-tooltip/desktop';
import { trackClickEvent } from '../../../../../components/map/track';
import { MapButtonDesktopProps, namespace, TYPE } from '../constants';

const MapButtonDesktop = ({
  url,
  label,
  melidata_track_value = null,
  analytics_track_event = null,
  tooltip = null,
  type,
}) => {
  const handleClick = (evt) => {
    evt.preventDefault();

    if (analytics_track_event && melidata_track_value) {
      trackClickEvent(analytics_track_event, melidata_track_value);
    }

    window.location.href = url;
  };

  const { hierarchy, IconComponent } = TYPE[type] || TYPE.DEFAULT;

  return (
    <div className={`${namespace}__button-container`}>
      <FilterTooltip className={`${namespace}__tooltip`} tooltip={{ ...tooltip, type: tooltip?.type || 'dark' }}>
        <Button size="medium" className={`${namespace}__button`} href={url} onClick={handleClick} hierarchy={hierarchy}>
          {IconComponent && <IconComponent className={`${namespace}__icon`} />}
          <ButtonText>{label}</ButtonText>
        </Button>
      </FilterTooltip>
    </div>
  );
};

MapButtonDesktop.propTypes = {
  ...MapButtonDesktopProps,
};

export default MapButtonDesktop;
