import React from 'react';
import { string, shape, arrayOf, bool, func } from 'prop-types';

import classNames from 'classnames';
import get from 'lodash/get';

import Link from '../link';

const namespace = 'ui-search-money-picker-desktop';
const emptyArray = [];

const MoneyPicker = ({
  className = '',
  currencies = emptyArray,
  filterValues = null,
  title = '',
  activeCurrencyId = null,
  handleCurrencyChange = null,
}) => {
  const createLiFilterValue = ({ id, name, url, results }) => (
    <li key={id} className={`${namespace}__li`}>
      <Link href={url} title={name}>
        <span className="ui-search-filter-name">{name}</span>
        <span className="ui-search-filter-results-qty">{results}</span>
      </Link>
    </li>
  );

  const renderValues = () => {
    const activeValues = get(filterValues, activeCurrencyId, []);

    return activeValues.map((it) => createLiFilterValue(it));
  };

  const handleClickMoneyPicker = (e, value) => {
    e.preventDefault();
    handleCurrencyChange(value.id);
  };

  return (
    <>
      <li>
        <h3 aria-level="3" className={`${namespace}-dt-title`}>
          {title}
        </h3>
        {currencies.length > 1 && (
          <div className={classNames(namespace, className)}>
            <div className={`${namespace}__content`}>
              {currencies.map((value) => (
                <button
                  type="button"
                  key={value.id}
                  className={`${namespace}__btn`}
                  id={value.id}
                  disabled={activeCurrencyId === value.id ? 'disabled' : ''}
                  onClick={(e) => {
                    handleClickMoneyPicker(e, value);
                  }}
                >
                  {value.symbol}
                </button>
              ))}
            </div>
          </div>
        )}
      </li>
      {filterValues && renderValues()}
    </>
  );
};

MoneyPicker.propTypes = {
  activeCurrencyId: string.isRequired,
  className: string,
  currencies: arrayOf(
    shape({
      id: string.isRequired,
      symbol: string.isRequired,
      selected: bool,
    }).isRequired,
  ),
  filterValues: shape().isRequired,
  handleCurrencyChange: func,
  title: string.isRequired,
};

export default MoneyPicker;
