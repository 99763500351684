import React from 'react';
import { element, string } from 'prop-types';

import ErrorBoundary from '../error/error-boundary';

const SearchComponentHandler = (props) => <ErrorBoundary {...props} />;

SearchComponentHandler.propTypes = {
  children: element.isRequired,
  componentName: string,
  customError: string,
  fallback: element,
  isCritical: string,
};

export default SearchComponentHandler;
