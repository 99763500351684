import React from 'react';
import { shape } from 'prop-types';

import { objValuesPropTypes } from '../../../../../components/sidebar/components/filters/propTypes';
import { namespace } from '../constants';

export const renderFilterGroup = (filter, renderer) =>
  Object.keys(filter).map((key) => (
    <div className={`${namespace}-filter-group`} key={key}>
      <div className={`${namespace}-filters-key`}>{key}</div>
      <div className={`${namespace}-filters`}>{filter[key].map(renderer)}</div>
    </div>
  ));

renderFilterGroup.propTypes = {
  filter: shape(objValuesPropTypes),
};
