import React from 'react';
import { string } from 'prop-types';

import classnames from 'classnames';

import { UI_SEARCH_FILTER_COLORS as namespace } from '../../../../constants/index';

const FiltersColors = ({ id = '', className = '' }) => (
  <div className={classnames(className, `${namespace} ${namespace}-${id}`)} />
);

FiltersColors.propTypes = {
  className: string,
  id: string,
};

export default FiltersColors;
