import React, { useEffect, useRef, useState } from 'react';
import { number, shape, string } from 'prop-types';

import ChevronRight from '@andes/icons/ChevronRight20';

import { trackEvent } from '../../../lib/melidata';
import useIntersectionObserver from '../../../hooks/use-intersection-observer';

const namespace = 'ui-search-segment';

const Header = ({ header, melidataTrack, viewMore }) => {
  const ref = useRef(null);
  const [isVisible] = useIntersectionObserver(ref, { rootMargin: '0px 0px -200px 0px' });
  const [wasTracked, setWasTracked] = useState(false);

  const { title } = header;
  const { link } = viewMore;

  useEffect(() => {
    let timeout;

    if (!wasTracked && melidataTrack) {
      timeout = setTimeout(() => {
        if (isVisible) {
          setWasTracked(true);

          const track = {
            ...melidataTrack,
            clean: true,
          };

          trackEvent(track);
        }
      }, 1000);
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [isVisible, melidataTrack, title, wasTracked]);

  return (
    <a href={link} className={`${namespace}__header`} ref={ref}>
      <h2 className={`${namespace}__title`} id={title}>
        {title}
      </h2>
      <ChevronRight />
    </a>
  );
};

Header.propTypes = {
  header: shape({
    title: string,
  }),
  melidataTrack: shape({
    event_data: shape({
      position: number,
      segment: string,
    }),
    path: string,
    type: string,
  }),
  viewMore: shape({
    link: string,
  }),
};

export default Header;
