import { node, string, bool, shape, number, oneOfType, arrayOf } from 'prop-types';

const ItemSortPropTypes = {
  children: node,
  className: string,
  disabled: bool,
  hasDisclaimer: bool,
  href: string,
  primary: node,
  selected: bool,
  value: oneOfType([number, string]),
};

const SortOption = shape({
  id: string,
  name: string,
  url: string,
  selected: bool,
});

const FilterSortPropTypes = {
  hasDisclaimer: bool,
  initialValue: SortOption,
  values: arrayOf(SortOption).isRequired,
};

const FilterSortContainerPropTypes = {
  has_disclaimer: bool,
  sorts: arrayOf(shape({})),
};

export { ItemSortPropTypes, FilterSortPropTypes, FilterSortContainerPropTypes };
