import React from 'react';

import { valuesPropTypes } from '../../../../components/sidebar/components/filters/propTypes';
import SearchModal from './components/SearchModal';
import { getContentRenderer } from './utils/getRenderers';

const SearchModalContainer = (props) => <SearchModal {...props} contentRenderer={getContentRenderer(props)} />;

SearchModalContainer.propTypes = {
  ...valuesPropTypes,
};

export default SearchModalContainer;
