import React from 'react';

import FiltersColors from '../../../filters-colors/filters-colors.desktop';
import { FilterLink } from '../../../../../link';
import { namespace, filterNamespace } from '../../constants';
import { objValuesPropTypes } from '../../../../../../components/sidebar/components/filters/propTypes';

const ColorFilterRenderer = ({ id, name, results, url }) => (
  <FilterLink
    className={`${namespace}-color-filter ${filterNamespace}-dd ${filterNamespace}-dd-color `}
    key={id}
    href={url}
    ariaLabel={`${name} ${results}`}
    title={name}
  >
    <FiltersColors name={name} id={id} className={`${namespace}-color-sample`} />
    <div className={`${namespace}-filter-data`}>
      <span className={`${namespace}-filter-name`}>{name}</span>
    </div>
  </FilterLink>
);

ColorFilterRenderer.propTypes = {
  ...objValuesPropTypes,
};

export default ColorFilterRenderer;
