import React from 'react';

import classnames from 'classnames';

import { FilterLink } from '../../../../../../../../components-v2/link';
import FilterTooltip from '../../../../../../../../components-v2/sidebar/components/filters-tooltip/desktop';
import { valueWithLinkConfigPropTypes } from '../../../../propTypes';
import { useSearch } from '../../../../../../../../hooks/context';

const ValueLinkWithText = ({ namespace, name, results, url, linkConfig, tooltip, filterId, accessibilityText }) => {
  const { shops } = useSearch();
  const isShops = Boolean(shops);

  const ariaLabel = accessibilityText ?? name;

  return (
    <FilterLink href={url} {...linkConfig} ariaLabel={ariaLabel} filterId={filterId} title={ariaLabel}>
      <FilterTooltip tooltip={tooltip}>
        <span className={classnames(`${namespace}-name`, { 'shops-custom-secondary-font': isShops })}>{name}</span>
        {results && (
          <span className={classnames(`${namespace}-results-qty`, { 'shops__filter-results-qty': isShops })}>
            {results}
          </span>
        )}
      </FilterTooltip>
    </FilterLink>
  );
};

ValueLinkWithText.propTypes = {
  ...valueWithLinkConfigPropTypes,
};

export default ValueLinkWithText;
