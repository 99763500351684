import React from 'react';
import { node, string, shape, number } from 'prop-types';

import classnames from 'classnames';
import { Tooltip as AndesTooltip } from '@andes/tooltip';

import useTooltip from '../../../../hooks/use-tooltip';
import { getFilterTooltipKey } from './helpers';
import { defaultPropTooltip, namespace } from './constants';

const FilterTooltip = ({ children, tooltip, className = '' }) => {
  const { title, offset_x, offset_y, content, side, type, max_print, filter_content_id } =
    tooltip ?? defaultPropTooltip;

  const localStorageKey = getFilterTooltipKey({ filter_content_id });
  const [tooltipStatus, handleClose] = useTooltip(localStorageKey);

  const shouldHideTooltip = tooltipStatus?.wasClosed || tooltipStatus?.views > max_print || !tooltip || !content;

  if (shouldHideTooltip) {
    return children;
  }

  return (
    <AndesTooltip
      className={classnames(namespace, className)}
      closable
      onClose={handleClose}
      title={title}
      content={content}
      offsetX={offset_x}
      offsetY={offset_y}
      side={side}
      type={type}
      trigger="no-trigger"
      visible
    >
      {children}
    </AndesTooltip>
  );
};

FilterTooltip.propTypes = {
  children: node.isRequired,
  className: string,
  tooltip: shape({
    title: string,
    offset_x: number,
    offset_y: number,
    filter_content_id: string,
    content: node,
    max_print: number,
  }),
};

export default FilterTooltip;
