import React from 'react';

import KeywordSummaryMobile from '@seo-frontend-components/keyword-summary-mobile';

import { useStaticProps } from '../../../components/context/static-props';
import {
  namespaceMobile,
  expandablePropsSize,
  expandablePropsColor,
  expandablePropsDefaultHeightMobile,
  seoInfoImageTextBoxInterventionPropTypes,
  preloadImage,
  charCountToExpand,
} from './constants';

const SeoInfoImageTextBoxIntervention = ({
  title,
  description,
  alt_image,
  image_url,
  expansion_text,
  contraction_text,
}) => {
  const { jsDisabled = false, country } = useStaticProps();
  const { andesLocale } = country;

  const summaryProps = {
    jsDisabled,
    title,
    description,
    image: {
      altImage: alt_image,
      imageUrl: image_url,
      fallbackImageUrl: image_url,
      preload: preloadImage,
    },
    expandableProps: {
      expansionText: expansion_text,
      contractionText: contraction_text,
      size: expandablePropsSize,
      defaultHeight: expandablePropsDefaultHeightMobile,
      color: expandablePropsColor,
    },
    locale: andesLocale,
    charCountToExpand,
  };

  return (
    <div className={`${namespaceMobile}__container`}>
      <KeywordSummaryMobile {...summaryProps} />
    </div>
  );
};

SeoInfoImageTextBoxIntervention.propTypes = {
  ...seoInfoImageTextBoxInterventionPropTypes,
};

export default SeoInfoImageTextBoxIntervention;
