import React from 'react';
import { shape, string } from 'prop-types';

import classnames from 'classnames';
import Image from 'nordic/image';
import { Tooltip } from '@andes/tooltip';

import { FilterLink } from '../../../link';
import ProcessedDescription from './components/processed-description';

const namespace = 'ui-search-filter-official-store';

const FilterOfficialStore = ({ id = '', name = '', expanded_values = null, className = '' }) => (
  <div className={classnames(className, `${namespace} ${namespace}-${id}`)}>
    {expanded_values.map((value) => (
      <div className={`${namespace}__container`} key={`${id}-${value.id}`}>
        <FilterLink title={value.name} href={value.url}>
          <Tooltip content={<ProcessedDescription {...value} />} side="bottom" className={`${namespace}__tooltip`}>
            <Image alt={name} className={`${namespace}__image`} src={value.logo_url} lowEnd />
          </Tooltip>
        </FilterLink>
      </div>
    ))}
  </div>
);

FilterOfficialStore.propTypes = {
  className: string,
  expanded_values: shape({}),
  id: string,
  name: string,
};

export default FilterOfficialStore;
