import React from 'react';

import { loadable } from 'nordic/lazy';

import { useSearch } from '../context/search';

const ChunkCallToActionPage = () => {
  const { call_to_action_page } = useSearch();
  const CallToActionPage = loadable(async () =>
    import(/* webpackChunkName: "chunk-call-to-action-page" */ './call-to-action-page'),
  );

  return call_to_action_page ? (
    <CallToActionPage text={call_to_action_page.text} callToAction={call_to_action_page.call_to_action} />
  ) : null;
};

export default ChunkCallToActionPage;
