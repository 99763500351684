import React from 'react';

import getIcon from '../../icons';
import { seoTopKeywordsInterventionPropTypes } from './propTypes';
import TextIconCarousel from '../filter-intervention/text-icon-carousel/mobile';

const SeoTopKeywordsIntervention = ({ keywords, title }) => {
  const namespace = 'ui-search-seo-top-keywords-intervention';

  return (
    <div className={`${namespace}__container`}>
      <h2 className={`${namespace}__title`}>{title}</h2>
      <TextIconCarousel tags={keywords} Icon={() => getIcon({ iconId: 'search' })} />
    </div>
  );
};

SeoTopKeywordsIntervention.propTypes = {
  ...seoTopKeywordsInterventionPropTypes,
};

export default SeoTopKeywordsIntervention;
