import { Component } from 'react';
import { bool, element, func, string } from 'prop-types';

import registerMetric from '../../../api/services/stats.service';
import {
  SEARCH_ERROR_HANDLER_DATADOG_KEY_CASES,
  SEARCH_ERROR_HANDLER_DATADOG_KEY_PREFIX,
  TAG_APP_NEME,
  UNKNOWN_VALUE,
} from '../../constants';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, error };
  }

  componentDidCatch(error) {
    const { newrelic } = window || {};
    const { customError } = this.props;

    if (newrelic) {
      newrelic.noticeError(error, {
        stack: error.stack,
        message: error.message,
      });
    }

    if (customError) {
      customError(error?.message);
    }
  }

  render() {
    const { children, siteId, fallback = null, componentName, isCritical = false } = this.props;

    if (!this.state.hasError) {
      return children;
    }

    registerMetric({
      key: `${SEARCH_ERROR_HANDLER_DATADOG_KEY_PREFIX}.${SEARCH_ERROR_HANDLER_DATADOG_KEY_CASES.JS_ERROR}`,
      tags: {
        error_type: 'ErrorBoundary',
        referer_app: TAG_APP_NEME || UNKNOWN_VALUE,
        error_component_name: componentName ?? UNKNOWN_VALUE,
      },
      messageTitle: 'Search-ErrorHandler - JsError',
    });

    if (isCritical && componentName !== 'MainPage') {
      window.ERROR_COMPONENT_NAME = componentName;

      throw new Error(`Critical error in component ${componentName}`);
    }

    return fallback?.({ siteId });
  }
}

ErrorBoundary.propTypes = {
  children: element.isRequired,
  componentName: string,
  customError: func,
  fallback: element,
  isCritical: bool,
  siteId: string,
};

ErrorBoundary.defaultProps = {
  customError: null,
};

export default ErrorBoundary;
