import React from 'react';
import { shape, string, bool } from 'prop-types';

import classnames from 'classnames';
import Card from '@andes/card';

import { MainImage } from '../common/main-image';
import Link from '../../../../../components-v2/link';
import { useStaticProps } from '../../../../context/static-props';
import ItemBookmark from '../../../../../components-v2/bookmark/bookmark';
import { useSearch } from '../../../../../hooks/context';
import { namespace } from '../../constants';
import { verticalContentRenderer } from '../../../../map/vertical-content';
import { arePropsEqual } from './helpers';

const MapLayoutItem = ({ item, options }) => {
  const { deviceType } = useStaticProps();
  const { bookmark } = useSearch();
  const { title, permalink, pictures, bookmarked } = item;
  const target = '_blank';
  const classNameLink = `${namespace}__content-link`;
  const version = 1;
  const titleA11Y = title;

  return (
    <Card animated={false} className={namespace}>
      <div className={`${namespace}__wrapper`}>
        <Link
          className={`${namespace}__main-image-link`}
          href={permalink}
          title={title}
          isInternal={false}
          target="_blank"
        >
          <MainImage pictures={pictures} item={item} options={options} />
        </Link>
        {verticalContentRenderer(item, deviceType, version, permalink, target, classNameLink, titleA11Y)}
        {bookmark?.show_user_bookmarks && (
          <div
            className={classnames(`${namespace}__bookmark`, {
              [`${namespace}__bookmark-active`]: bookmarked,
            })}
          >
            <ItemBookmark item={item} />
          </div>
        )}
      </div>
    </Card>
  );
};

MapLayoutItem.propTypes = {
  item: shape({
    id: string.isRequired,
    title: string.isRequired,
    permalink: string.isRequired,
    vertical: string.isRequired,
    pictures: shape({}),
  }).isRequired,
  options: shape({
    lowEnd: bool,
  }),
};

MapLayoutItem.defaultProps = {
  options: undefined,
};

export default React.memo(MapLayoutItem, arePropsEqual);
