// key: nombre del icono en el componente
// value: nombre del file en la carpeta sources
const listIcon = {
  ahora_30: 'ahora30',
  alert_edit: 'alert-edit',
  alert_edit_v2: 'alert-edit-v2',
  alert_icon_new_mobile: 'alert-edit-v2',
  alert_new: 'alert-new',
  alert_new_v2: 'alert-edit-v2',
  arrow: 'arrow',
  badge: 'badge',
  big_brands: 'big-brands',
  black_friday: 'black-friday',
  black_friday_icon: 'black-friday',
  blue_check: 'blue-check',
  bookmark: 'bookmark',
  calendar: 'calendar',
  carrot: 'carrot',
  cart_volume_discount: 'cart-volume-discount',
  cart_icon: 'cart-volume-discount',
  cbt_international: 'international-cbt',
  cbt_international_desktop: 'international-cbt',
  cbt_international_mobile: 'international-cbt',
  chevron: 'chevron',
  chevron_right: 'icon-chevron-right',
  close: 'close',
  cockade: 'cockade',
  coupon: 'icon-coupons',
  cpg: 'cpg-more',
  delivery: 'delivery',
  'destaque-compatible-icon': 'elipse-check-icon',
  elipse_check: 'elipse-check-icon',
  error_screen: 'error-screen',
  eshop: 'eshop',
  filter_toolbar: 'toolbar-filter',
  fulfillment: 'full',
  free: 'shipping-truck',
  full_icon: 'full',
  full_super: 'full-super',
  full_super_mlb: 'full-super-mlb',
  full_super_small: 'full-super',
  full_super_small_mlb: 'full-super-mlb',
  gallery_desktop: 'gallery.desktop',
  gallery_toolbar: 'toolbar-gallery',
  gift: 'gift',
  good_financing: 'good_financing',
  help_headset: 'help-headset',
  hot_sale: 'hot-sale',
  icon_pharma: 'pharma',
  ic_gift: 'ic-gift',
  ic_ahora30: 'ahora30',
  ic_hot_sale: 'hot-sale',
  info_16: 'icon-info-16',
  installation: 'installation-base',
  installation_base: 'installation-base',
  installations_icon: 'installation-base',
  international_cbt: 'international-cbt',
  international_filter_cbt: 'international-cbt',
  international_filter_mobile: 'international-cbt',
  international_logo: 'international-logo',
  international_cbt_no_world: 'international-cbt-no-world',
  is_highlight_brand_premium: 'premium-brands',
  is_highlight_brand: 'big-brands',
  like: 'like',
  list_desktop: 'list.desktop',
  list_mobile: 'list.desktop',
  list_toolbar: 'list.desktop',
  loader_error: 'map-loader-error',
  loyalty: 'loyalty',
  nextday: 'sameday_truck',
  map: 'map',
  market: 'market',
  melichoice_logo: 'melichoice',
  melichoice_icon: 'melichoice',
  melicoin: 'icon-melicoin',
  melicoin_icon: 'icon-melicoin',
  meli: 'meli',
  meli_icon: 'meli',
  meliplus: 'meli_plus_poly',
  meliplus_poly: 'meli_plus_poly',
  meli_plus_icon: 'meli_plus_poly',
  mshops_logo: 'mshops-logo',
  mosaic_toolbar: 'toolbar-mosaic',
  my_account: 'my-account',
  not_found: 'not-found',
  offer: 'offer',
  online_visit_request: 'online-visit-request',
  online_visit_icon: 'online-visit-request',
  order_toolbar: 'toolbar-order',
  pills_warning: 'pills_warning',
  play: 'play',
  premium_brands: 'premium-brands',
  question: 'question',
  question_mark: 'question-mark',
  'question-mark': 'question-mark',
  recos_full: 'full',
  rest: 'rest',
  return_purchase: 'return-purchase',
  sameday: 'sameday_truck',
  sameday_truck: 'sameday_truck',
  sameday_turbo: 'sameday_truck',
  search: 'search-light',
  search_bookmark_created_icon: 'search-bookmark-created',
  search_bookmark_created_icon_mobile: 'search-bookmark-created',
  search_bookmark_icon: 'search-bookmark',
  search_bookmark_icon_mobile: 'search-bookmark',
  search_light: 'search-light',
  search_toolbar: 'search-light',
  shipping_truck: 'shipping-truck',
  star: 'circled_star',
  star_empty: 'star-empty',
  star_full: 'star-full',
  star_half: 'star-half',
  sum: 'sum',
  supermarket: 'supermarket-fs',
  supermarket_logo: 'supermarket-fs',
  supermarket_logo_full: 'supermarket-fs',
  trade: 'trade',
  virtual_tour: 'virtual-tour',
  virtual_tour_icon: 'virtual-tour',
  warning_icon: 'warning',
  weekdeals: 'weekdeals',
  zoom_in: 'map-zoom-in',
  zoom_out: 'map-zoom-out',
  ic_navigation_coupons_ilustrated: 'ilustrated-coupons',
  ic_navigation_help_headset_ilustrated: 'ilustrated-help',
  ic_navigation_myaccount_ilustrated: 'ilustrated-my-account',
  ic_navigation_password_ilustrated: 'ilustrated-password',
  ic_navigation_purchases_ilustrated: 'ilustrated-purchases',
  ic_navigation_return_purchase_ilustrated: 'ilustrated-return-purchase',
  ic_navigation_weekdeals_ilustrated: 'ilustrated-weekdeals',
  f1_carv2: 'f1-car',
  f1_car_turbov2: 'f1-car-turbo.js',
  'recommendations-mshops_logo': 'full',
  'recommendations-cpg_icon': 'cpg-more',
  'recommendations-meli_coins': 'icon-melicoin',
  'recommendations-meli_logo': 'meli',
  'recommendations-chevron': 'chevron',
  'recommendations-black-friday_icon': 'black-friday',
  'recommendations-heart-empty': 'bookmark',
  'recommendations-heart-full': 'bookmark',
  'recommendations-full_icon': 'full',
  color: 'icon-color',
  green_check: 'green-check',
  payment: 'payment',
};

export default listIcon;
