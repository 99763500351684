import React from 'react';

import Tooltip from '@andes/tooltip';

import { FilterLink } from '../../../../../../components-v2/link';
import FiltersColors from '../../../../../../components-v2/sidebar/components/filters-colors/filters-colors.desktop';
import { colorValuesPropTypes } from '../../propTypes';

const RenderColorsValues = ({ namespace, id, name, results, url }) => (
  <li className={`${namespace}-dd ${namespace}-dd-color`} key={id} aria-label={name} id={name}>
    <Tooltip side="bottom" content={results} title={name} className={`${namespace}-tooltip`} mouseLeaveDelay={0}>
      <FilterLink href={url} aria-label={`${name} ${results}`} name={name} title={name}>
        <FiltersColors name={name} id={id} />
      </FilterLink>
    </Tooltip>
  </li>
);

RenderColorsValues.propTypes = {
  ...colorValuesPropTypes,
};

export default RenderColorsValues;
