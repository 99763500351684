import { node, string } from 'prop-types';

import EshopFactory from './utils/eshop-factory';

const componentCreation = (comp, siteId) => EshopFactory({ ...comp, siteId });

const WrapperEshopDesktop = ({ children, siteId }) => {
  const componentWrapper = children?.map((component) => componentCreation(component, siteId));

  return componentWrapper || null;
};

WrapperEshopDesktop.propTypes = {
  children: node.isRequired,
  siteId: string.isRequired,
};

export default WrapperEshopDesktop;
