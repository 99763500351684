import React, { useState } from 'react';
import { string } from 'prop-types';

import constuctClassName from 'classnames';
import { Switch } from '@andes/switch';

import HighlightedFilterLabelContent from './label-content';
import { FILTER_EXTENDED_PROPS, NAMESPACE, QUESTION_ICON_ID } from '../constants';
import { FilterTooltip } from '../../filters-tooltip/mobile';
import usePageLoad from '../../../../../hooks/use-page-load';
import getIcon from '../../../../icons';
import HighlightedWrapper from './highlighted-desktop-wrapper';
import { useHighlightsFilters } from '../hooks/use-highlights-filters';
import { useSearch } from '../../../../../hooks/context';

const HighlightedFilter = ({
  filterId,
  switch: switchValue,
  highlight = null,
  label = null,
  subtitle = null,
  title = null,
  tooltip = null,
  url = '',
}) => {
  const { is_on: isOnSwitch } = switchValue || {};
  const [isChecked, setIsChecked] = useState(isOnSwitch);
  const { shops } = useSearch();
  const isShops = Boolean(shops);
  const isPageLoaded = usePageLoad();
  const { onClickHighlighted } = useHighlightsFilters({ setIsChecked, url, filterId });

  const HighlightedContent = (
    <>
      <Switch
        className={constuctClassName(`${NAMESPACE}__switch`, { 'shops__highlighted-switch': isShops })}
        id={filterId}
        label={
          <HighlightedFilterLabelContent
            namespace={NAMESPACE}
            highlight={highlight}
            label={label}
            subtitle={subtitle}
            title={title}
            htmlForIDValue={filterId}
          />
        }
        checked={isChecked}
        onChange={() => onClickHighlighted()}
        labelPosition="left"
      />

      {tooltip && (
        <div className={`${NAMESPACE}__tooltip`}>
          <FilterTooltip tooltip={{ ...tooltip, side: 'right' }} onClose={() => {}}>
            <div className={`${NAMESPACE}__tooltip-trigger`} />
            {getIcon({ iconId: QUESTION_ICON_ID })}
          </FilterTooltip>
        </div>
      )}
    </>
  );

  return (
    <HighlightedWrapper namespace={NAMESPACE} isShops={isShops} isPageLoaded={isPageLoaded} url={url}>
      {HighlightedContent}
    </HighlightedWrapper>
  );
};

HighlightedFilter.propTypes = {
  ...FILTER_EXTENDED_PROPS,
  filterId: string.isRequired,
};

export default HighlightedFilter;
