import React from 'react';

import classnames from 'classnames';
import KeywordSummary from '@seo-frontend-components/keyword-summary';

import { useStaticProps } from '../../../components/context/static-props';
import {
  namespace,
  expandablePropsSize,
  expandablePropsColor,
  expandablePropsDefaultHeightDesktop,
  polycardDesktop,
  seoKeywordSummaryInterventionPropTypes,
  preloadImage,
} from './constants';

const SeoKeywordSummaryIntervention = ({
  keyword,
  summary,
  alt_image,
  image_url,
  expansion_text,
  contraction_text,
}) => {
  const { jsDisabled = false, country } = useStaticProps();
  const { andesLocale } = country;

  const summaryProps = {
    jsDisabled,
    title: keyword,
    description: summary,
    image: {
      altImage: alt_image,
      imageUrl: image_url,
      fallbackImageUrl: image_url,
      preload: preloadImage,
    },
    expandableProps: {
      expansionText: expansion_text,
      contractionText: contraction_text,
      size: expandablePropsSize,
      defaultHeight: expandablePropsDefaultHeightDesktop,
      color: expandablePropsColor,
    },
    locale: andesLocale,
  };

  const containerClassNames = classnames(polycardDesktop, `${namespace}__container`);

  return (
    <div className={containerClassNames}>
      <KeywordSummary {...summaryProps} />
    </div>
  );
};

SeoKeywordSummaryIntervention.propTypes = {
  ...seoKeywordSummaryInterventionPropTypes,
};

export default SeoKeywordSummaryIntervention;
