/* eslint-disable import/order */
import React from 'react';

import { shape, number } from 'prop-types';

import { RecommendationsShops } from '@recommendations-fe/shops';

import IconFactory from '../../../icons/utils/factory-builder';

import { useUser } from '../../../../components/context/user/index';

const namespace = 'ui-search-sidebar-placements';

const SearchShopsAds = ({ request: { params }, subtitle, ads_to_show }) => {
  const { id: userId } = useUser();
  const callbackNoRecos = () => {
    const wrapper = document.querySelector(`#${namespace}__wrapper`);

    if (wrapper) {
      wrapper.style.display = 'none';
    }
  };

  return (
    <div id={`${namespace}__wrapper`} className={`${namespace}__wrapper`}>
      <div className={`${namespace}__container`}>
        <RecommendationsShops
          layout="search"
          onCarouselIsEmpty={callbackNoRecos}
          siteId={params.site_id}
          d2Id={params.d2_id}
          limit={ads_to_show}
          itemId={params.item_id}
          userId={userId}
          categoryId={params.category_id}
          subtitle={subtitle}
          client={params.client}
          {...params}
          IconFactory={IconFactory()}
        />
      </div>
    </div>
  );
};

SearchShopsAds.propTypes = {
  ads_to_show: number.isRequired,
  request: shape({}),
  subtitle: shape({}).isRequired,
};

export default SearchShopsAds;
