import React, { useState } from 'react';
import { string, shape, bool } from 'prop-types';

import classNames from 'classnames';

import { useSearch } from '../../hooks/context';
import { useUser } from '../../components/context/user';
import { useStaticProps } from '../../components/context/static-props';
import APIService from '../../../services/bookmarks';
import { trackEvent } from '../../lib/analytics';
import { toggleBookmark } from '../../components/context/search';
import { NAMESPACE, DEFAULT_PROPS } from './constans';
import redirectTo from '../../utils/redirect-to';
import BookmarkIconComponent from './components/bookmark-icon-components';

const { DEFAULT_ITEM, DEFAULT_CLASSNAME } = DEFAULT_PROPS;

const Bookmark = ({ className = DEFAULT_CLASSNAME, item = DEFAULT_ITEM }) => {
  const { bookmarked, id, item_id, vertical, product } = item;
  const [bookmarkState, setBookmarkState] = useState(!!bookmarked);
  const [isFetching, setIsFetching] = useState(false);
  const { loggedIn, loginUrl } = useUser();
  const { analytics_track, bookmark } = useSearch();
  const { isHoverDisabled } = useStaticProps();
  const [showOnFocus, setShowOnFocus] = useState(isHoverDisabled);
  const itemId = id || item_id || null;
  const replaceRule = { 'loginType=explicit': 'loginType=favorite' };

  const getGALabel = () => {
    const path = analytics_track?.pathFromRoot || [];
    const lastItem = path[path.length - 1]?.name || '';

    return lastItem.replaceAll(',', '').replaceAll(' ', '_').toUpperCase();
  };

  const handleBookmarkFocus = () => {
    if (!isHoverDisabled) {
      setShowOnFocus(!showOnFocus);
    }
  };

  const handleBookmark = async (ev) => {
    ev.preventDefault();

    if (isFetching) {
      return;
    }

    if (!loggedIn) {
      redirectTo(loginUrl, { replaceRules: replaceRule });

      return;
    }

    const newBookmarkState = toggleBookmark(itemId);

    setBookmarkState(!bookmarkState);
    setIsFetching(true);

    if (newBookmarkState && vertical === 'CPG') {
      trackEvent({
        category: 'SUPERMERCADO',
        action: 'ADD_FAVORITE',
        label: `${product ? 'PDP_' : 'VIP_'}${getGALabel()}`,
      });
    }

    try {
      await APIService.toggleBookmarkService(itemId, !bookmarkState);
    } catch (ex) {
      if (ex.response?.status === 403) {
        redirectTo(loginUrl, { replaceRules: replaceRule });

        return;
      }

      setBookmarkState(newBookmarkState);
      toggleBookmark(itemId);
    } finally {
      setIsFetching(false);
    }
  };

  return (
    <form
      action={`/search/bookmarks/${itemId}`}
      className={classNames(NAMESPACE, className, {
        [`${NAMESPACE}--active`]: bookmarkState,
        [`${NAMESPACE}--hover-disabled`]: showOnFocus,
      })}
      method="POST"
      onSubmit={async (ev) => handleBookmark(ev)}
      onFocus={handleBookmarkFocus}
      onBlur={handleBookmarkFocus}
    >
      <button
        type="submit"
        className={`${NAMESPACE}__btn`}
        role="switch"
        aria-checked={bookmarkState}
        aria-label={bookmark?.label?.text || 'Bookmark'}
      >
        <BookmarkIconComponent bookmarkState={bookmarkState} />
      </button>
      <input type="hidden" name="logginUrl" value={loginUrl} />
      <input type="hidden" name="method" value={bookmarkState ? 'remove' : 'add'} />
      <input type="hidden" name="itemId" value={itemId} />
    </form>
  );
};

Bookmark.propTypes = {
  className: string,
  item: shape({
    bookmarked: bool,
    id: string,
    item_id: string,
    vertical: string,
    product: shape({}),
  }).isRequired,
};

export default Bookmark;
