import React from 'react';
import { bool, oneOf, shape, string } from 'prop-types';

import classnames from 'classnames';

import RenderComponent from './utils/renderComponent';
import LabelContent from './components/label-content';
import { getVariablesFromText } from './utils/utilities';
import { PACKAGE_NAMESPACE_LABEL } from './constants';
import component from '../../components/snackbar/component';

const LabelBuilder = (props) => {
  const { class_name, html_tag, text, type, styleLabel = {}, styles } = props;

  const Interpolationkeys = getVariablesFromText(text);

  if (Interpolationkeys) {
    return <LabelContent {...props} className={class_name} />;
  }

  const Component = RenderComponent(type);
  const Element = html_tag || 'span';

  return (
    <Element
      className={classnames(
        PACKAGE_NAMESPACE_LABEL,
        { 'ui-search-styled-label--shops': styleLabel?.isShop },
        { [`ui-search-size--${styleLabel?.font_size}`]: styleLabel?.font_size },
        { [`ui-search-weight--${styleLabel?.font_family}`]: styleLabel?.font_family },
        class_name,
      )}
      style={styles}
    >
      {Component ? component({ ...props }) : text}
    </Element>
  );
};

LabelBuilder.propTypes = {
  class_name: string,
  html_tag: oneOf(['p', 'span', 'h1', 'h2', 'h3', 'h4', 'li']),
  styleLabel: shape({
    color: string,
    font_family: string,
    font_size: string,
    isShop: bool,
  }),
  styles: shape({}),
  text: string,
  type: string,
};

export default LabelBuilder;
