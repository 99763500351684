import componentFactoryFrom from './factory';
import types from './component-types';
import grouped from '../grouped/grouped';
import breadcrumb from '../../components-v2/sidebar/components/breadcrumb';
import appliedFilters from '../filters/applied/applied-filters.desktop';
import filters from '../sidebar/components/filters/filters.desktop';
import alert from '../alert/alert.desktop';
import searchSave from '../alert-v2/search-save.desktop';
import searchResult from '../../components-v2/sidebar/components/search-result';
import moneyPicker from '../../components-v2/money-picker/desktop';
import skyBanner from '../../components-v2/sidebar/components/sky-banner';
import searchShops from '../../components-v2/sidebar/components/search-shops-ads/index';
import advertisingAdn from '../advertising-adn/index';
import styledLabel from '../styled-label/styled-label';
import { MapButtonDesktop as mapButton, MapLinkDesktop as mapLink } from '../../components-v2/sidebar/components/map';

const sources = {};

sources[types.GROUPED] = grouped;
sources[types.BREADCRUMB] = breadcrumb;
sources[types.MAP_LINK] = mapLink;
sources[types.MAP_BUTTON] = mapButton;
sources[types.APPLIED_FILTERS] = appliedFilters;
sources[types.AVAILABLE_FILTERS] = filters;
sources[types.ALERT] = alert;
sources[types.ALERT_V2] = searchSave;
sources[types.TOTAL_RESULTS] = searchResult;
sources[types.MONEY_PICKER] = moneyPicker;
sources[types.SIDEBAR_ADVERTISING] = skyBanner;
sources[types.SEARCH_SHOPS_ADS] = searchShops;
sources[types.ADN] = advertisingAdn;
sources[types.ACCESIBLE_LABEL] = styledLabel;

const ComponentFactory = componentFactoryFrom(sources);

export default ComponentFactory;
