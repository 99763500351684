/* eslint-disable react/prop-types */
import React from 'react';

import { loadable } from 'nordic/lazy';

import SeoTopKeywordsIntervention from '../../../../components-v2/intervention/seo-top-keywords-intervention/seo-top-keywords-intervention.mobile';
import SeoKeywordSummaryIntervention from '../../../../components-v2/intervention/seo-keyword-summary-intervention/seo-keyword-summary-intervention.mobile';

const FilterIntervention = loadable(async () =>
  import(
    /* webpackChunkName: "chunk-intervention-filter" */
    '../../../../components-v2/intervention/filter-intervention/mobile'
  ),
);

const ContentIntervention = loadable(async () =>
  import(
    /* webpackChunkName: "chunk-intervention-content" */
    '../../../../components-v2/intervention/content/content-intervention.mobile'
  ),
);

const CarouselIntervention = loadable(async () =>
  import(
    /* webpackChunkName: "chunk-intervention-carousel" */
    '../../../../components-v2/intervention/carousel/carousel-intervention'
  ),
);

const BillboardIntervention = loadable(async () =>
  import(
    /* webpackChunkName: "chunk-intervention-billboard" */
    '../../../../components-v2/intervention/billboard/billboard-intervention.mobile'
  ),
);

const CompatsIntervention = loadable(async () =>
  import(
    /* webpackChunkName: "chunk-intervention-compats" */
    '../../../../components-v2/intervention/compats/compats-intervention'
  ),
);

const BrandAdsMobile = loadable(async () =>
  import(
    /* webpackChunkName: "chunk-intervention-brand-ads" */
    '../../../brand-ads/brand-ads.mobile'
  ),
);

const ShoppingCartIntervention = loadable(async () =>
  import(
    /* webpackChunkName: "chunk-intervention-shopping-cart" */
    '../../shopping-cart'
  ),
);

const TrendIntervention = loadable(async () =>
  import(
    /* webpackChunkName: "chunk-intervention-trend" */
    '../../../../components-v2/intervention/trend-intervention/trend-intervention.mobile'
  ),
);

const BrandDiscoIntervention = loadable(async () =>
  import(
    /* webpackChunkName: "chunk-intervention-brand-disco" */
    '../../../../components-v2/intervention/brand-disco/brand-disco.mobile'
  ),
);

const MeliPlayIntervention = loadable(async () =>
  import(
    /* webpackChunkName: "chunk-intervention-meliplay" */
    '../../../../components-v2/intervention/meliplay/stack/meliplay-intervention.mobile'
  ),
);

const ZrpDisclaimerIntervention = loadable(async () =>
  import(
    /* webpackChunkName: "chunk-intervention-zrp-disclaimer" */
    '../../../../components-v2/intervention/zrp-disclaimer/zrp-disclaimer'
  ),
);

const AndesMessageIntervention = loadable(async () =>
  import(
    /* webpackChunkName: "chunk-intervention-andes-messsage" */
    '../../andes-message-intervention/andes-message-intervention'
  ),
);

const MoreInfoIntervention = loadable(async () =>
  import(
    /* webpackChunkName: "chunk-intervention-more-info" */
    '../../more-info-intervention/more-info-intervention.mobile'
  ),
);
const QuickAccessIntervention = loadable(async () =>
  import(
    /* webpackChunkName: "chunk-quick-access-intervention" */
    '../../quick-access-intervention/mobile'
  ),
);

// Reminder: When creating a new intervention component, the top hierarchical node must be a `div` so as not to generate a mismatch error.

const renderInterventionsMobile = {
  FILTER_INTERVENTION: ({ item }) => <FilterIntervention intervention={item} />,
  CONTENT_INTERVENTION: ({ item }) => <ContentIntervention intervention={item} />,
  CAROUSEL_INTERVENTION: ({ item: { content } }) => <CarouselIntervention intervention={content} />,
  BILLBOARD_INTERVENTION: ({ item: { contents: items } }) => <BillboardIntervention intervention={items} />,
  COMPATS_INTERVENTION: ({ item }) => <CompatsIntervention intervention={item} />,
  BRAND_ADS_INTERVENTION: ({ item }) => <BrandAdsMobile intervention={item} />,
  CART_INTERVENTION: ({ item }) => <ShoppingCartIntervention intervention={item} />,
  TREND_INTERVENTION: ({ item }) => <TrendIntervention intervention={item} />,
  BRAND_DISCO_INTERVENTION: ({ item, position }) => <BrandDiscoIntervention intervention={item} position={position} />,
  MELIPLAY_INTERVENTION: ({ item, position }) => <MeliPlayIntervention intervention={item} position={position} />,
  SEO_TOP_KEYWORDS_INTERVENTION: ({ item }) => <SeoTopKeywordsIntervention {...item} />,
  ZRP_DISCLAIMER_COMPONENT: ({ item }) => <ZrpDisclaimerIntervention intervention={item} />,
  ANDES_MESSAGE_INTERVENTION: ({ item }) => <AndesMessageIntervention intervention={item} />,
  PHARMA_INTERVENTION: ({ item }) => <MoreInfoIntervention intervention={item} />,
  QUICK_ACCESS_INTERVENTION: ({ item }) => <QuickAccessIntervention intervention={item} />,
  SEO_KEYWORD_SUMMARY_INTERVENTION: ({ item }) => <SeoKeywordSummaryIntervention {...item} />,
};

export { renderInterventionsMobile };
