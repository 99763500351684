import React from 'react';

import Advertising from '../../../../components/advertising/advertising';
import { namespace, SkyBannerPropTypes } from './constants';

const SkyBanner = ({
  google_ad,
  segmentation,
  personal_data_ads_denied,
  slot_id,
  viewport_margin = null,
  title = null,
}) => (
  <div className={`${namespace}__display`}>
    <Advertising
      slotId={slot_id}
      googleAd={google_ad}
      personalDataAdsDenied={personal_data_ads_denied}
      viewportMargin={viewport_margin}
      segmentation={segmentation}
      title={title}
      namespace={namespace}
    />
  </div>
);

SkyBanner.propTypes = {
  ...SkyBannerPropTypes,
};

export default SkyBanner;
