import React, { useMemo } from 'react';
import { string } from 'prop-types';

import classnames from 'classnames';

import Highlight from '../../../../../components/card/highlight/highlight-label';
import IconLabel from '../../../../icons/utils/icon-label'; // TODO: check if we can be removed this component
import StyledLabelWrapper from '../../../../../components/styled-label/styled-label';
import { FILTER_BASE_PROPS, MELI_PLUS } from '../constants';

const HighlightedFilterLabelContent = ({
  namespace = '',
  title = null,
  highlight = null,
  subtitle = null,
  label = null,
  htmlForIDValue = '',
  hasHtml,
}) => {
  const getTitleClassname = useMemo(
    () =>
      classnames(`${namespace}__title`, {
        [`${namespace}__${MELI_PLUS}`]:
          title && Array.isArray(title.icons) && title?.icons?.[0] === `${MELI_PLUS}_icon`,
      }),
    [title, namespace],
  );

  return (
    <>
      {title && (
        <IconLabel className={getTitleClassname} htmlForIDValue={htmlForIDValue} hasHtml={hasHtml} {...title} />
      )}
      {highlight && <Highlight htmlForIDValue={htmlForIDValue} {...highlight} />}
      {subtitle && <IconLabel className={`${namespace}__subtitle`} htmlForIDValue={htmlForIDValue} {...subtitle} />}
      {label && <StyledLabelWrapper className={`${namespace}__label`} htmlForIDValue={htmlForIDValue} {...label} />}
    </>
  );
};

HighlightedFilterLabelContent.propTypes = {
  ...FILTER_BASE_PROPS,
  namespace: string,
};

export default HighlightedFilterLabelContent;
