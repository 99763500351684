import React from 'react';

import IconMap from '../../../../icons/sources/map';
import FilterTooltip from '../../filters-tooltip/desktop';
import { trackClickEvent } from '../../../../../components/map/track';
import { namespace, MapLinkDesktopProps } from '../constants';

const MapLinkDesktop = ({
  url,
  label,
  melidata_track_value = null,
  analytics_track_event = null,
  tooltip = null,
  hasIcon = true,
}) => {
  const handleClick = () => {
    if (analytics_track_event && melidata_track_value) {
      trackClickEvent(analytics_track_event, melidata_track_value);
    }
  };

  return (
    <section>
      <a className={`${namespace}__link`} href={url} onClick={handleClick}>
        <FilterTooltip tooltip={tooltip}>
          {hasIcon && <IconMap className={`${namespace}__icon-map`} />}
          <span>{label}</span>
        </FilterTooltip>
      </a>
    </section>
  );
};

MapLinkDesktop.propTypes = {
  ...MapLinkDesktopProps,
};

export default MapLinkDesktop;
