import React from 'react';
import { arrayOf, bool, shape, string } from 'prop-types';

import classnames from 'classnames';

import { useSearch } from '../../../hooks/context';
import ComponentFactory from '../../../components/factory/factory.desktop';

const namespace = 'ui-search-sidebar';

const Sidebar = ({ components, multiNeighborhood = false }) => {
  const { shops } = useSearch();
  const isShops = Boolean(shops);

  return (
    <aside
      className={classnames(namespace, {
        [`${namespace}--h1-multi-neighborhood`]: multiNeighborhood,
        shops__sidebar: isShops,
      })}
    >
      {components?.map((component) => (
        <ComponentFactory {...component} key={component?.id} namespace={namespace} type={component?.type} />
      ))}
    </aside>
  );
};

Sidebar.propTypes = {
  components: arrayOf(
    shape({
      type: string,
      html_tag: string,
      label: shape({
        text: string,
      }),
      nodes: arrayOf(
        shape({
          text: string,
          url: string,
        }),
      ),
    }),
  ).isRequired,
  id: string,
  multiNeighborhood: bool,
  type: string,
};

export default Sidebar;
