import React from 'react';
import { string, bool, node } from 'prop-types';

import classNames from 'classnames';

const HighlightedWrapper = ({ namespace, isShops, isPageLoaded, url, children }) => (
  <div
    className={classNames(`${namespace}__container`, {
      'shops__filter-highlighted': isShops,
    })}
  >
    {isPageLoaded ? children : <a href={url}>{children}</a>}
  </div>
);

HighlightedWrapper.propTypes = {
  children: node,
  isPageLoaded: bool,
  isShops: bool,
  namespace: string,
  url: string,
};

export default HighlightedWrapper;
